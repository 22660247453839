import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "gatsby";
import Layout from "../layouts";
import MapChart from "../components/MapChart";
import schools from '../data/data.json';
import coverImage from '../images/cover.png';
import loadable from "@loadable/component";

const JWPlayer = loadable(() => import("@jwplayer/jwplayer-react"));

export default function Home() {
  const [district, setDistrict] = useState('all');

  return (
    <Layout title="林蔭下教育：新界和離島學校的故事">
      <div id="main" className="px-4 py-14 lg:px-20 lg:py-20">
        <h1 class="text-center text-xl lg:text-4xl font-medium lg:mb-2">
          林蔭下教育：新界和離島學校的故事
        </h1>
        <h1 class="text-center lg:text-2xl mb-6">
          Education under the Trees: The Story of Hong Kong Schools in the New
          Territories and Outlying Islands
        </h1>
        <div className="lg:px-8">
          <div className="lg:mb-8 mb-6">
            <JWPlayer
              library="https://cdn.jwplayer.com/libraries/j4rTOaLQ.js"
              file="https://stream.lib.eduhk.hk:1935/nt-schools/mp4:NT_Trailer_v2-hi-res.mp4/playlist.m3u8"
              config={{
                autostart: true,
                playbackRateControls: true,
              }}
            />
          </div>
          <MapChart />
        </div>
      </div>
      <div id="intro" class="relative px-4 py-14 lg:px-20 lg:py-20 bg-gray-50">
        <h1 class="section-title text-center text-xl lg:text-4xl font-medium mb-8">
          本書簡介
        </h1>
        <img
          src={coverImage}
          className="lg:h-72 h-48 lg:mx-10 mx-auto my-10 lg:float-right"
          alt=""
        />
        <p class="lg:text-lg leading-loose mb-5">
          昔日鄉村學校遍布新界與離島，為農村和漁村兒童提供基礎教育。隨着城巿發展和時代變遷，村校由戰後高峰時期的八百多所，到如今僅餘不足二十所，並各以不同的轉變應對當前辦學和教育的需要。
        </p>
        <p class="lg:text-lg leading-loose mb-5">
          本書嘗試探索新界離島各區的教育發展，梳理區內學校的起源，以及它們最新的發展與轉變。本書旨在回顧新界和離島的教育發展里程，藉著與各校師長、校友訪談，從他們對成長經歷的回憶，分享昔日鄉村校園生活的趣聞軼事—學生上學時出入阡陌、不同級別學生在同一教室上課、放學後需協助家中農務或出海打魚等。點點滴滴勾勒出各鄉各村的歷史和發展，從而印證教育與社區一起的蛻變的片段。
        </p>
        <p class="lg:text-lg leading-loose mb-5">
          書中涵蓋新界和離島學校的起源與發展、新界師範教育歷史、地區特色、人物訪談、傳統文化習俗等多個範疇，引領讀者回到上個世紀，感受新界和離島學生的求學生活，並深入介紹社區節慶習俗，從多個角度去了解新界和離島鄉村學校與整體社區文化生活脈絡的互動。
        </p>
        <p class="leading-loose mb-5">
          Village schools used to be a common sight throughout the New
          Territories and outlying islands, offering basic education to children
          in rural and fishing villages. Following urban development, the number
          of village schools dropped from the post-WWII peak of more than 800 to
          less than 20 today. The remaining schools have transformed in various
          ways to respond to the present demands of school operation and
          changing educational needs.
        </p>
        <p class="leading-loose mb-5">
          This book traces the origin of these schools and the development of
          education in the New Territories and outlying islands. Drawing from
          personal interviews, it chronicles the memories and anecdotes of
          teachers and alumni, portraying what it was like to come of age in the
          countryside. The book takes readers back to the last century to
          experience the lives of village school students, at a time when
          teachers and pupils walked through crisscrossing farmland paths to get
          to school, when pupils from different year levels shared a classroom,
          and when after-school activities included helping with the family’s
          farm or fishing business.
        </p>
        <p class="leading-loose mb-5">
          The book also demonstrates how the village schools in the area
          interacted with the social culture of the wider community. The history
          and development of the villages are pieced together, illustrating how
          schooling and teacher education have grown and evolved alongside
          changes to these communities. It offers an in-depth account of
          traditional customs and festivities, highlights milestones of
          educational development in the region, and reports on the present
          state of these village schools.
        </p>
      </div>
      <div id="ack" class="px-4 py-14 lg:px-20 lg:py-20">
        <h1 class="section-title text-center text-xl lg:text-4xl font-medium mb-8">
          鳴謝
        </h1>
        <p class="text-lg">* 學校 / 機構名稱按中文筆劃排序</p>
        <p className="mt-3">
          本書內容經多番考證修訂，編著者李子建、鄭保瑛、鄧穎瑜及香港教育大學香港教育博物館團隊，謹此致謝中華書局（香港）出版社的鼎力支持，並感謝以下一眾學校、機構和每一位的支持、分享和賜正，並為本書提供珍貴的照片：
        </p>
        <table class="w-full mt-5">
          <thead>
            <th
              colspan="2"
              class="border-t-2 border-b-2 text-left py-4 text-lg font-medium px-2 bg-gray-100"
            >
              學校 / 機構
            </th>
          </thead>
          <tbody>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                八鄉中心小學
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>黎婉姍校長</p>
                <p>蔡玉寧博士</p>
                <p>楊惠蓮女士</p>
                <p>鄧鏡河副校長</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                上水區鄉事委員會
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>廖世鴻先生</p>
                <p>黃思琪小姐</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                上水鄉鄉公所
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>廖家樂先生</p>
                <p>廖汶琪小姐</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                大埔浸信會公立學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>蔡碧蕊校長</p>
                <p>何惠玲女士</p>
                <p>林小康先生</p>
                <p>林秋霞女士</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                大埔舊墟公立學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>張麗珠校長</p>
                <p>林惠玲女士</p>
                <p>羅錦全先生</p>
                <p>陳志超先生</p>
                <p>鄭敏嫻副校長</p>
                <p>徐麗裘老師</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                大澳鄉事委員會
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>陳潔華女士</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                中華基督教會拔臣小學
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>鍾惠娟校長</p>
                <p>鄧英敏先生</p>
                <p>張偉良先生</p>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                古物古蹟辦事處
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                打鼓嶺嶺英公立學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>朱國強校長</p>
                <p>杜錦貴先生</p>
                <p>黃偉東先生</p>
                <p>李俊民先生</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                沙頭角中心小學
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>馮瑞蘭校長</p>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                非物質文化遺產辦事處
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                東義造船業總商會
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>梁國明先生</p>
                <p>鍾甜妹女士</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                金錢村何東學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>吳毓琪校長</p>
                <p>陳朝基先生</p>
                <p>侯樹球先生</p>
                <p>伍麗冰副校長</p>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                政府檔案處歷史檔案館
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                香港大學圖書館
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                香港文化博物館
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                香港公共圖書館
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                香港特別行政區政府新聞處
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                香港記憶
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                香港歷史博物館
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                粉嶺公立學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>余美賢校長</p>
                <p>何瑞明女士</p>
                <p>李榮基教授</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                荃灣公立何傳耀紀念小學
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>朱慧敏校長</p>
                <p>王玉玲女士</p>
                <p>傅成志先生</p>
                <p>陳浩源先生</p>
                <p>鄭頌霖主任</p>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                荃灣公立何傳耀紀念中學
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                福德學社小學
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>陳劍青校長</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                國民學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>郭婉琪校長</p>
                <p>黃成就先生</p>
                <p>李慶欣先生</p>
                <p>樊麗貞副校長</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                惇裕學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>陳杏軒校長</p>
                <p>文祿星先生</p>
                <p>文富穩先生</p>
                <p>文富財先生</p>
                <p>文偉昌先生</p>
                <p>文炳喜先生</p>
                <p>姜小康老師</p>
                <p>曾瑞如行政主任</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                梅窩學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>郭敏麗校長</p>
                <p>袁哲之先生</p>
                <p>佘基本先生</p>
                <p>黃曉艷小姐</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                鳳溪第一小學
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>朱偉林校長</p>
                <p>廖子良先生</p>
                <p>潘幸慧小姐</p>
                <p>廖超華先生</p>
                <p>嚴慧思小姐</p>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                鳳溪公立學校
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                錦田鄉事委員會
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>鄧賀年先生</p>
                <p>鄧滿堂先生</p>
                <p>鄧達兼先生</p>
                <p>鄧有光先生</p>
                <p>鄧浩然先生</p>
                <p>鄧偉添先生</p>
                <p>周雅怡小姐</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 border-t border-b py-4 px-2 lg:text-lg font-medium">
                鐘聲學校
              </td>
              <td class="w-1/2 border-t border-b py-4 lg:text-lg">
                <p>王玉麟校長</p>
                <p>張綺芳女士</p>
                <p>陳求德醫生</p>
                <p>譚志源先生</p>
                <p>鍾港武先生</p>
                <p>張帝弼先生</p>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                South China Morning Post（ 南華早報）
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 lg:text-lg"
              >
                The National Archives of the UK ( 英國國家檔案館)
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 text-lg bg-gray-100"
              >
                個人
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                田仲一成教授
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                江啟明先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                何偉全先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                何連發先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                何蓮娣女士
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                廖迪生教授
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                廖崇興先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                廖駿駒先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                鄭寶鴻先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                樊森記先生
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                Professor Janet Lee Scott（ 施天賜教授）
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="border-t-2 border-b-2 font-medium text-left py-4 px-2 text-lg bg-gray-100"
              >
                場地提供
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                一家人士多
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-t border-b py-4 px-2 lg:text-lg">
                集古齋
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="team" class="relative px-4 py-14 lg:px-20 lg:py-20 bg-gray-50">
        <h1 class="section-title text-center text-xl lg:text-4xl font-medium mb-8">
          編著及製作團隊
        </h1>
        <table class="w-full mt-5">
          <thead>
            <th
              colspan="2"
              class="border-t-2 border-b-2 text-left py-4 text-lg font-medium"
            >
              本書編委會
            </th>
          </thead>
          <tbody>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                「香港教育故事」 叢書系列主編
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>
                  李子建教授（香港教育大學學術及首席副校長、教育承傳計劃顧問委員會主席）
                </p>
                <p>鄭保瑛博士（香港教育大學圖書館館長）</p>
                <p>鄧穎瑜女士（香港教育大學香港教育博物館館長）</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                本書編著者
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>李子建教授</p>
                <p>鄭保瑛博士</p>
                <p>鄧穎瑜女士</p>
                <p>黃詠筠小姐（香港教育大學香港教育博物館助理館長）</p>
                <p>陳君堯小姐（香港教育大學香港教育博物館助理館長）</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                資料搜集及協助撰稿
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>鄭保瑛博士</p>
                <p>鄧穎瑜女士</p>
                <p>黃詠筠小姐</p>
                <p>陳君堯小姐</p>
                <p>楊昱航先生（香港教育大學香港教育博物館助理館長）</p>
                <p>葉佩聰小姐（香港教育大學圖書館助理館長）</p>
                <p>戚紹忠先生（香港教育大學圖書館助理館長）</p>
                <p>江婉怡女士（香港教育大學圖書館助理館長）</p>
                <p>林朗程小姐（香港教育大學圖書館行政主任）</p>
                <p>趙美絲小姐（香港教育大學圖書館項目助理）</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                人物專訪主持及支援團隊
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>李子建教授（專訪主持）</p>
                <p>鄭保瑛博士</p>
                <p>鄧穎瑜女士</p>
                <p>黃詠筠小姐</p>
                <p>陳君堯小姐</p>
                <p>林朗程小姐</p>
                <p>張希彤女士（香港教育大學學術及首席副校長辦公室行政主任）</p>
                <p>芮筠庭女士（香港教育大學學術及首席副校長辦公室行政主任）</p>
                <p>蕭琸琳女士（香港教育大學學術及首席副校長辦公室行政助理）</p>
                <p>陳咏怡女士（香港教育大學學術及首席副校長辦公室研究助理）</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                拍攝、錄影及短片編輯
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>黃嘉樂先生（香港教育大學圖書館媒體製作主任）</p>
                <p>崔朗聰先生（香港教育大學圖書館圖書館助理）</p>
                <p>盧卓暉先生（香港教育大學圖書館圖書館助理）</p>
                <p>黃詠筠小姐</p>
                <p>陳君堯小姐</p>
                <p>楊昱航先生</p>
                <p>趙美絲小姐</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                網站程式編寫
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>徐偉隆先生（香港教育大學圖書館助理館長）</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/3 border-t border-b py-4 font-medium lg:text-lg">
                地圖繪製
              </td>
              <td class="border-t border-b py-4 lg:text-lg px-2 lg:px-0">
                <p>陳慧羚小姐（香港教育大學圖書館項目助理）</p>
                <p>黃巧儀小姐（香港教育大學圖書館項目助理）</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="interviews" class="px-4 py-14 lg:px-20 lg:py-20">
        <h1 class="section-title text-center text-xl lg:text-4xl font-medium mb-8">
          專訪錄影
        </h1>
        <div>
          <ul class="hidden nav-select font-medium text-center text-gray-500 rounded-lg shadow sm:flex mb-8">
            <li class="w-full">
              <button
                class="inline-block p-4 w-full focus:ring-4 focus:ring-blue-300 focus:outline-none current"
                className={clsx(
                  "inline-block p-4 w-full focus:outline-none",
                  district === "all" ? "current" : ""
                )}
                onClick={() => setDistrict("all")}
              >
                所有地區
              </button>
            </li>
            {schools.districts.map((d, i) => (
              <li key={i} class="w-full">
                <button
                  className={clsx(
                    "inline-block p-4 w-full focus:outline-none",
                    district === d.id ? "current" : ""
                  )}
                  onClick={() => setDistrict(d.id)}
                >
                  {schools.districts_norm[d.id].name}
                </button>
              </li>
            ))}
          </ul>
          <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4 mb-5">
            {schools.districts
              .filter((item) => district === "all" || item.id === district)
              .map((item) => item.schools)
              .flat()
              .map((item, i) => (
                <Link
                  key={i}
                  to={`/${item}`}
                  class="flex flex-col items-center mb-3 lg:mb-3"
                >
                  <img
                    src={schools.schools[item].cover_image}
                    className="lg:h-48 h-32 object-cover"
                  />
                  <p class="lg:text-lg text-center mt-1 lg:mt-3">
                    {schools.schools[item].name}
                  </p>
                  <p className="lg:text-lg text-gray-400">
                    {
                      schools.districts_norm[schools.schools[item].district]
                        .name
                    }
                  </p>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div id="disclaimer" class="px-4 py-14 lg:px-20 lg:py-20 bg-gray-50">
        <h1 class="section-title text-center text-xl lg:text-4xl font-medium mb-8">
          免責聲明
        </h1>
        <p class="lg:text-lg">
          錄影內容只反映訪問者與受訪者之個人意見，並不代表香港教育大學及其立場。
        </p>
      </div>
    </Layout>
  );
};