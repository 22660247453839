import React, { useRef, useEffect, useState, useCallback } from "react";
import { navigate } from "gatsby";
import Map, { Source, Layer, useControl } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import boundaries from '../data/hksar_18_district_boundary.json';
import districts from "../data/districts.json";
import schools from "../data/schools_geo.json";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const lat = 22.3496907;
const lng = 114.1468908;

const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "schools",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#d00",
      100,
      "#f1f075",
      750,
      "#f28cb1",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "schools",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-size": 14
  },
  paint: {
    "text-color": "#fff"
  }
};

const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "circle",
  source: "schools",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#d00",
    "circle-radius": 6,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};

const unclusteredTextLayer = {
  id: "unclustered-text",
  type: "symbol",
  source: "schools",
  filter: ["!", ["has", "point_count"]],
  layout: {
    "text-field": ["get", "name"],
    "text-size": 13,
    "text-offset": [0, 1.5],
  },
  paint: {
    "text-color": "#333",
    "text-halo-color": "#fff",
    "text-halo-width": 2,
  },
};

const districtLayer = {
  type: "symbol",
  layout: {
    "text-field": ["get", "name"],
    "text-size": 18,
    "text-offset": [0, 0],
  },
  paint: {
    "text-color": "#484848",
    // "text-halo-color": "#565f7e",
    // "text-halo-width": 10,
  },
};



const LanguageControl = () => {
  useControl(() => new MapboxLanguage({ defaultLanguage: 'zh-Hant'}));
  return null;
};

const MapChart = () => {
  const [cursor, setCursor] = useState("auto");
  const mapRef = useRef();

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  const onClick = (event) => {
    const feature = event.features[0];
    
    if (feature) {
      const clusterId = feature.properties.cluster_id;
      const unclusteredId = feature.properties.id;
      
      if (clusterId) {
        const mapboxSource = mapRef.current.getSource('schools');

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapRef.current.easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 1000,
          });
        });
      }

      if (unclusteredId) {
        navigate(`/${unclusteredId}`);
      }
    }
  };

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        // longitude: lng,
        // latitude: lat,
        zoom: 8,
        bounds: [
          [113.8450307, 22.1956648],
          [114.3167178, 22.5502123],
        ],
        fitBoundsOptions: {
          padding: 40,
        },
      }}
      interactiveLayerIds={[
        "clusters",
        "unclustered-text",
        "unclustered-point",
      ]}
      mapStyle="mapbox://styles/mapbox/light-v10"
      mapboxAccessToken="pk.eyJ1IjoiZWR1aGtsaWIiLCJhIjoiY2wzMnFobmtmMG1rOTNqcDlhMWRsNnQycyJ9.BEfPNaSQ-zY6SiCTFYZmjg"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor={cursor}
    >
      <LanguageControl />
      {boundaries.map((boundary) => (
        <Source
          key={boundary.id}
          id={`district-${boundary.id}`}
          type="geojson"
          data={boundary.geojson}
        >
          {boundary.layers.map((layer, i) => (
            <Layer key={i} {...layer} />
          ))}
        </Source>
      ))}
      <Source id="districts" type="geojson" data={districts}>
        <Layer {...districtLayer} />
      </Source>
      <Source
        id="schools"
        type="geojson"
        data={schools}
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
        <Layer {...unclusteredTextLayer} />
      </Source>
    </Map>
  );
};

export default MapChart;